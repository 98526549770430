import React, { useContext } from "react"
import classNames from "classnames"
import { useStaticQuery, graphql, navigate } from "gatsby"

import Hero from "layout/Hero"
import Container from "layout/Container"
import Button from "elements/Button"
import CompleteProfileModal from "../HomeBanner/CompleteProfileModal"

import styles from "../utils/staticPages.module.scss"
import { getSignedInUser, hasRoles } from "../../Auth/services/user"
import { AppContext } from "../../../context/AppContext"

const HomeBanner = () => {
  const data = useStaticQuery(graphql`
    {
      heart: file(relativePath: { eq: "landing_page/landing_rx-beat.png" }) {
        childImageSharp {
          fluid(
            duotone: { highlight: "#ffffff", shadow: "#0d6d6e" }
            toFormat: PNG
            quality: 90
            maxWidth: 1000
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const heart = data.heart.childImageSharp.fluid.src

  const user = getSignedInUser()?.authUser
  const { dispatch } = useContext(AppContext)

  const handleClick = (url) => {
    if (user && hasRoles()) {
      navigate(`/intake/${url}`)
    } else if (user && !hasRoles()) {
      dispatch({
        type: "SHOW_MODAL",
        payload: {
          heading: "Complete your profile",
          isCard: true,
          headerClass: `has-text-info has-background-info-light has-text-weight-bold is-size-5`,
          content: <CompleteProfileModal />,
        },
      })
    } else {
      navigate("/verify-email")
    }
  }

  return (
    <Hero
      size="medium"
      background={{
        image: heart,
        position: "center",
      }}
    >
      <Container isCentered desktop={10} fullhd={8}>
        <Container mobile={12} tablet={8} desktop={8} padding={0}>
          <h1 className="title is-size-1 is-size-2-mobile has-text-primary">
            Book a one‑on‑one
            <br className="is-hidden-mobile" /> with a mental health
            professional
          </h1>

          <p className="subtitle is-size-4 pt-2">
            Who are you requesting a teleconsult for?
          </p>

          <div className="buttons">
            <Button
              color="primary"
              size="medium"
              onClick={() => handleClick("self")}
              className={classNames(styles["home__buttonIsFullwidth"])}
            >
              Myself
            </Button>
            <Button
              color="primary"
              size="medium"
              variant="outlined"
              onClick={() => handleClick("refer")}
              className={classNames(styles["home__buttonIsFullwidth"])}
            >
              Someone Else
            </Button>
          </div>
        </Container>
      </Container>
    </Hero>
  )
}

export default HomeBanner
