import React, { Fragment } from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"

import Container from "layout/Container"
import Message from "components/Elements/Message"
import CoachingSVG from "../../../../static/images/svgs/landing_coaching.svg"
import CounselingSVG from "../../../../static/images/svgs/landing_counseling.svg"

import coachingVsCounseling from "../utils/coachingVsCounseling.json"

const CoachingVsCounseling = () => {
  return (
    <Fragment>
      <Container isCentered desktop={10} fullhd={8}>
        <center>
          <h3 className="mt-5 has-text-centered has-text-gray-dark">
            What’s the difference between{" "}
            <span className="has-text-taskus-blue">coaching</span> and{" "}
            <span className="has-text-primary">counseling</span>?
          </h3>
          <p>Know which intervention is best for your concerns</p>
        </center>
        <div className="content is-medium">
          {/* <table className="table has-text-centered my-2">
            <thead>
              <tr>
                {coachingVsCounseling?.headers?.map((header) => (
                  <th>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {coachingVsCounseling?.rows?.map((row) => (
                <tr>
                  <td>{row[0]}</td>
                  <td>{row[1]}</td>
                </tr>
              ))}
            </tbody>
          </table> */}

          {coachingVsCounseling?.rows?.map((row, index) => (
            <Fragment>
              <div className="columns is-mobile" key={index}>
                <div className="column">
                  {index === 0 ? (
                    <h5 className="has-text-centered has-text-taskus-blue">
                      {/* <Img fixed={coaching} /> */}
                      <center>
                        <CoachingSVG />
                      </center>
                      <br /> Coaching
                    </h5>
                  ) : null}
                  <Message color="light">{row[0]}</Message>
                </div>
                <div className="column">
                  {index === 0 ? (
                    <h5 className="has-text-centered has-text-primary">
                      {/* <Img fixed={counseling} /> */}
                      <center>
                        <CounselingSVG />
                      </center>
                      <br /> Counseling
                    </h5>
                  ) : null}
                  <Message color="light">{row[1]}</Message>
                </div>
              </div>
            </Fragment>
          ))}

          <center>
            <h3 className="has-text-centered has-text-gray-dark">
              Who should I book time with?
            </h3>
            <p>Align your goals with who can help you most</p>
          </center>
          <div className="columns my-2">
            <div className="column">
              <Message color="light">
                <span className="subtitle">If you want to talk about...</span>
                <ul type="disc">
                  {coachingVsCounseling?.concerns?.coaching?.map((concern) => (
                    <li>{concern}</li>
                  ))}
                </ul>
                <a
                  className="button is-taskus-blue is-medium is-fullwidth"
                  href="https://boost.taskus.com/wellness"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Book Coaching Session{" "}
                  <FontAwesomeIcon
                    icon={faExternalLinkAlt}
                    size="sm"
                    className="ml-1"
                  />
                </a>
              </Message>
            </div>

            <div className="column">
              <Message color="light">
                <span className="subtitle">If you want to talk about...</span>
                <ul type="disc">
                  {coachingVsCounseling?.concerns?.counseling?.map(
                    (concern) => (
                      <li>{concern}</li>
                    )
                  )}
                </ul>
                <Link
                  className="button is-primary is-medium is-fullwidth"
                  to="/intake/self/"
                >
                  Book Mental Health Consult
                </Link>
              </Message>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default CoachingVsCounseling
